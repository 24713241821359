@import url("https://cdn.jsdelivr.net/gh/poposnail61/min-sans@main/web/css/minsans-dynamic-subset.css");

#App {
  max-width: 480px;
  margin: 0 auto;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  width: auto;
  position: relative;
  word-break: keep-all;
  /*font-family: 'Noto Sans KR', sans-serif;*/
}
:root{
  --primary-color: #005950; /* 학생회 대표 색상 */
  --primary-similar-color: #00595099; /* 학생회 대표 색상 60% - State.js 에서 사용*/
  --red-color: #D43434; /* 신청취소 button 색상 */
  --gray-color: #9C9C9C; /* Button disabled 색상 */
  --light-gray-color: #D9D9D9; /* 반납완료 button 색상 */
  --white-color: #ffffff; /* 버튼 글자 색상 */
  --black-color: #000000; /* 검은색 */
}
